import { entity } from 'simpler-state';
import axios from 'axios';

const api = axios.create({
    baseURL: process.env.GATSBY_API_URL,
    timeout: 2000,
});

export const Emissions = entity({
    loading: false,
    error: true,
    emissions: [],
    errorMsg: null,
})

export const fetchEmissions = async (apiKey) => {
    Emissions.set( { ...Emissions.get(), loading: true });

    const result = await api.get(`/emissions`, { withCredentials: true, headers: { 'x-api-key': apiKey } });
    if (result.status !== 200) {
        Emissions.set({ ...Emissions.get(), loading: false, error: true, errorMsg: result.status })
    } else {
        Emissions.set({ ...Emissions.get(), loading: false, emissions: result.data })
    }
}

export const createEmission = async  (apiKey, data) => {
    data.carbon_impact = parseFloat(data.carbon_impact)
    const result = await api.post('/emissions', data, {withCredentials: true, headers: { 'x-api-key': apiKey }})
    if (result.status !== 200) {
        return null;
    } else {
        return result.data;
    }
}

export const getOneEmission = async (apiKey, id) => {
    const result = await api.get(`/emissions/${id}`, { withCredentials: true, headers: { 'x-api-key': apiKey }})
    if (result.status !== 200) {
        return null
    } else {
        Emissions.set({ ...Emissions.get(), view: result.data });
    }
}

export const createEmissionQuote = async (apiKey, data) => {
    const result = await api.post('/emissions/quotes', data, {withCredentials: true, headers: { 'x-api-key': apiKey }})
    if (result.status !== 200) {
        return null;
    } else {
        return result.data;
    }
}