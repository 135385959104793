import React, { useState } from 'react';
import { Checkbox, Select, Input, Box, Button, Text, Badge, Accordion, AccordionButton, AccordionPanel, AccordionItem, AccordionIcon, Flex } from "@chakra-ui/react"
import { CheckIcon, CloseIcon } from '@chakra-ui/icons'
import JSONPretty from 'react-json-pretty';

import { Field, Form, Formik, useFormikContext } from 'formik';
import * as yup from 'yup';
import { createQuote, Quotes } from '../modules/offsets_module';
import { createEmission, Emissions} from '../modules/emissions_module';
import { Keys } from '../modules/keys_module';


const validationSchema = yup.object().shape({
    carbon_impact: yup.number().typeError("Must be a number").required("Must enter a quantity"),
    // track: yup.bool()
});

const ShowPreview = () => {
    const formikContext = useFormikContext()
    return(
        <code>
            <JSONPretty data={JSON.stringify(formikContext?.values)} />
        </code>
    )
}

const CreateEmissionForm = ({ afterSubmit, currentKey }) => {
    const keys = Keys.use();
    
    return (
    <div>
        <Formik
            initialValues={{ carbon_impact: '' }}
            validationSchema={validationSchema}
            validateOnBlur
            onSubmit={async (values, {setSubmitting, setErrors, setStatus, resetForm}) => {
                setSubmitting(true)
                const data = await createEmission(keys.activeKey, values)
                Emissions.set({...Emissions.get(), loading: false, emissions: [...Emissions.get().emissions, data]});
                resetForm()
                setSubmitting(false)
                afterSubmit(data.Uid)
            }}
        >
            {({
                values,
                handleChange,
                handleBlur,
                touched,
                errors,
                setFieldValue,
                submitForm,
            }) => (
                <form>
                    <p className="mb-2">Make a quick quote for carbon a carbon offset</p>
                    <label htmlFor="qty">Carbon (kg)</label>
                    <Box className="space-y-2 mb-3">
                    <Input
                        id="qty"
                        name="carbon_impact"
                        value={values.carbon_impact}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {touched.carbon_impact && errors.carbon_impact && <div className="text-error">{errors.carbon_impact}</div>}
                    <Button type="submit" onClick={submitForm} disabled={values.carbon_impact == '' || errors.carbon_impact}>
                        Create
                    </Button>
                    </Box>
                    {/* <hr /> */}
                    <Accordion allowMultiple allowToggle>
                        <AccordionItem>
                            {({ isExpanded }) => (
                                <>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left">
                                        API Request
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel pb={4}>
                                    <Box className="bg-gray-700 text-green-500 p-4 rounded-lg">
                                        <Box className="bg-gray-100 rounded-md p-2 text-gray-800 mb-2 flex items-center space-x-2">
                                            <Badge bg="gray.700" textColor="purple.300">Post</Badge>
                                            <Text mb={0} className="text-xs">https://api.getkodama.com/emissions/</Text>
                                        </Box>
                                        <Box className="bg-gray-100 rounded-md p-2 text-gray-800 mb-2 flex items-center space-x-2">
                                            <Badge bg="gray.700" textColor="purple.300">x-api-key</Badge>
                                            <Text mb={0} className="text-xs">{currentKey}</Text>
                                        </Box>
                                        <ShowPreview />
                                    </Box>
                                </AccordionPanel>
                                </>
                            )}
                        </AccordionItem>
                        
                    </Accordion>
                </form>
        )}
        </Formik>
    </div>
    );
};

export default CreateEmissionForm;