import { navigate } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import AppPageContainer from '../../../components/AppPageContainer';
import BasicModal from '../../../components/BasicModal';
import CreateEmissionForm from '../../../components/create-emission-form';
import ToolBar from '../../../components/ToolBar';
import { Keys } from '../../../modules/keys_module';
import { Quotes, fetchQuotes } from '../../../modules/offsets_module';
import { Box, Button, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Tag } from '@chakra-ui/react';
import { Emissions, fetchEmissions } from '../../../modules/emissions_module';
import TagManager from '../../../components/tag-manager';


const EmissionsPage = () => {
    const emissions = Emissions.use();
    const keys = Keys.use();

    const quoteRefs = useRef(new Map())

    const [createQuoteModal, setCreateQuoteModal] = useState(false);
 
    useEffect(() => {
        fetchEmissions(keys.activeKey)
    }, [keys.activeKey, keys.activeEnv])

    const afterCreate = (uid) => {
        setCreateQuoteModal(false)
        quoteRefs.current[uid].scrollIntoView({ behaivior: 'smooth', block: 'start' })
    }

    const [taggable, setTaggable] = useState()
    const [tagMoadal, setTagModal] = useState(false)
    const toggleTagModal = (emission) => {
        setTaggable(emission)
        openTagModal()
    }
    const openTagModal = () => { setTagModal(true) }
    const closeTagModal = () => { 
        fetchEmissions(keys.activeKey)
        setTagModal(false)
    }

    return (
        <AppPageContainer activePage="emissions">
            <BasicModal close={() => setCreateQuoteModal(false)} isOpen={createQuoteModal} title="Quick Quote">
                <CreateEmissionForm afterSubmit={afterCreate} />
            </BasicModal>
            <BasicModal title={`Tags ${taggable?.Uid}`} close={closeTagModal} isOpen={tagMoadal}>
                <TagManager objectType="Emission" objectId={taggable?.Uid} taggables={taggable?.Tags} />
            </BasicModal>
            <ToolBar pageName="Your Offsets" />
            { (emissions?.additionalInfo && emissions?.error) && (
                <div className="mt-4 p-3 border-error border rounded-lg bg-error bg-opacity-20">
                    Your currently selected API environment does not have an active API key.
                </div>
            )}
            <Box my={4} p={2} className=" bg-gray-100 border border-gray-300 rounded-md">
                <Button colorScheme="green" onClick={() => setCreateQuoteModal(true)} >Track Emission</Button>
            </Box>
            { (emissions?.emissions) && (
                <div className="my-8">
                    {emissions.emissions?.map(o => (
                        <div ref={(element) => quoteRefs.current[o.Uid] = element} className="mt-4 p-3 border border-gray-200 rounded-lg w-full shadow-md" key={o.Uid}>
                            <div className="space-x-2 flex items-center">
                                {o.Classification && (<Tag>{o.Status}</Tag>)}
                                <span className="text-xs text-primary-dark">{o.Uid}</span>
                                {o.Tags.map(t => (
                                    <Tag key={t.Uid}>{t.DataTag.Name}</Tag>
                                ))}
                                <Button size="xs" colorScheme="green" onClick={() => toggleTagModal(o)}>Edit Tags</Button>
                            </div>
                            <div className="grid grid-cols-4 mt-3">
                                <div className="mr-4 col-span-1">
                                    <div className="text-xs">Total Impact</div>
                                    <div className="font-bold">{o.CarbonImpact}kg CO2</div>
                                </div>
                                <div className="col-span-1">
                                    <div className="text-xs">Quote Coverage</div>
                                    <div className="font-bold">{o.EmissionToQuotes.reduce((p, c) => p + c.CoveragePercent, 0)}%</div>
                                </div>
                                <div className="col-span-1">
                                    <div className="text-xs">Emission Coverage</div>
                                    <div className="font-bold">{o.EmissionToQuotes.filter(e2q => e2q.Quote.Status.toLowerCase() === "purchased").reduce((p, c) => p + c.CoveragePercent, 0)}%</div>
                                </div>
                                <div className="col-span-1">
                                    <Button size="sm" onClick={() => {navigate(`/app/emissions/view/${o.Uid}`)}}>View</Button>
                                    {/* <Popover>
                                        <PopoverTrigger>
                                            <Button size="xs" colorScheme="green">Actions</Button>
                                        </PopoverTrigger>
                                        <PopoverContent>
                                            <PopoverArrow />
                                            <PopoverCloseButton />
                                            <PopoverHeader>
                                                Emission Actions
                                            </PopoverHeader>
                                            <PopoverBody>
                                                <div>
                                                    <Button width="full" variant="outline" colorScheme="gray">
                                                        Offset Emission
                                                    </Button>
                                                </div>
                                            </PopoverBody>
                                        </PopoverContent>
                                    </Popover> */}
                                </div>
                            </div>
                            <hr className="my-2" />
                            {o.EmissionToQuotes.map(em => (
                                <div key={em.Quote.Uid} className="grid grid-cols-4 space-y-1 items-center">
                                    <div className="flex items-center">
                                        <Tag colorScheme="green">{em.Quote.Status}</Tag>
                                        <div className="text-xs px-2">{em.Quote.Uid}</div>
                                    </div>
                                    <div>{em.CoveragePercent}% Covered</div>
                                    <div>${em.Quote.Cost / 100}</div>
                                    <div>{em.Quote.carbon_impact}kg</div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            )}
        </AppPageContainer>
    );
};

export default EmissionsPage;

